.step {
    &__image {
        margin-bottom: rem(30px);
        text-align: center;

        .img-fluid {
            display: inline-block;
        }
    }

    &__text {
        margin-bottom: rem(60px);
        font-size: rem(18px);
        font-weight: 500;
        letter-spacing: rem(1px);
        text-align: center;
        color: theme-color("primary");
    }

    &__tipologia-mezzo {
        .form-radio--image .form-radio__label .image {
            border: #EFEFEF 1px solid;
        }
    }

    &__misure-mezzo {
        &--motrice-rimorchio {
            .step__image {
                margin-bottom: 0;
            }
        }
    }

    &__cricchetti {
        .bottom-fields {
            .color-label {
                margin-bottom: rem(10px);
                font-size: rem(12px);
            }

            .form-input {
                margin-bottom: 0;
            }

            .form-radio-color {
                margin: 0 0 0 rem(10px);

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    &__title {
        margin-bottom: rem(30px);
        font-size: rem(20px);
        font-weight: 500;
        text-align: center;
        color: theme-color("primary");
    }

    &__radio-label {
        display: block;
        margin-bottom: rem(15px);
        font-size: rem(13px);
    }

    &__option {
        padding-top: rem(15px);
        margin-top: rem(15px);
        border-top: #EBEBEB 1px solid;

        &:first-child {
            padding-top: 0;
            margin-top: 0;
            border-top: none;
        }

        .form-input {
            margin: 0;

            &:after {
                display: none;
            }

            &__control {
                height: auto;
                padding: rem(15px) rem(40px);
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .step {
        &__teli-laterali,
        &__cricchetti,
        &__tipologia-tetto,
        &__colore-tetto {
            .form-radio {
                margin-bottom: rem(40px);

                &__label {
                    .image {
                        width: 100%;
                    }
            
                    .text {
                        left: 50%;
                        right: auto;
                        top: auto;
                        bottom: 0;
                        transform: translate(-50%, 50%);
                    }
                }
            }
        }

        &__cricchetti {
            .form-radio {
                &__label {
                    .text {
                        width: 95%;
                    }
                }
            }

            .bottom-fields {
                .form-radio-color {
                    width: rem(32px);
                    height: rem(32px);
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .step {
        &__image,
        &__title {
            margin-bottom: rem(60px);
        }

        &__form {
            .row {
                margin-right: rem(-20px);
                margin-left: rem(-20px);
    
                > div {
                    padding-right: rem(20px);
                    padding-left: rem(20px);
                }
            }
        }

        &__text {
            margin-bottom: rem(75px);
            font-size: rem(22px);
        }

        &__cricchetti {
            .form-radio {
                &__label {
                    .text {
                        width: 90%;
                        max-width: initial;
                        padding-right: rem(15px);
                        padding-left: rem(15px);
                    }
                }

                &__children {
                    width: 95%;

                    .form-radio {
                        width: calc(100% + #{rem(20px)});
                    }
                }
            }
            
            .bottom-fields {
                .color-label {
                    margin-bottom: 0;
                    font-size: rem(15px);
                }

                .form-radio-color {
                    margin: 0 0 0 rem(15px);
    
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        &__reflex {
            .form-radio-color {
                vertical-align: middle;
                margin-bottom: 0;

                &:last-child {
                    margin-right: 0;
                }
            }

            .bottom-fields__fake-label {
                margin-right: rem(15px);
            }
        }
    }
}
