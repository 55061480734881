div.configurator {
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    // min-height: 100vh;
    // background: radial-gradient(circle, #fff 50%, #f2f2f2 100%);

    &__header {
        position: relative;
        border-bottom: #eee 1px solid;

        .title {
            padding-top: rem(10px);
            padding-bottom: rem(10px);
            margin-bottom: 0;
            font-size: rem(15px);
            font-weight: 400;
            letter-spacing: rem(0.7px);
            text-align: center;
        }

        .svg-icon{
            &--logo-symbol {
                width: rem(20px);
                color: theme-color("primary");
            }
        }
        
        .container-fluid {
            > .row {
                min-height: rem(60px);
            }
        }
    }

    &__content {
        min-height: calc(var(--vh, 1vh) * 100 - #{rem(148px)});

        > .container-fluid {
            min-height: calc(var(--vh, 1vh) * 100 - #{rem(148px)});

            > .row {
                min-height: calc(var(--vh, 1vh) * 100 - #{rem(148px)});
                // padding-bottom: rem(86px);

                > div {
                    padding-top: rem(30px);
                    padding-bottom: rem(30px);
                }
            }
        }
    }

    &__footer {
        padding-top: rem(15px);
        padding-bottom: rem(15px);
    }
}

@include media-breakpoint-down(md) {
    div.configurator {
        &__footer {
            background: #fff;

            .btn-prev {
                width: rem(56px);
                height: rem(56px);
                padding: 0 !important;
                border: #c0c0c0 1px solid;
                border-radius: 100%;
    
                .icon {
                    left: 50% !important;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    div.configurator {
        &__header {
            .title {
                padding-top: rem(20px);
                padding-bottom: rem(20px);
                font-size: rem(22px);
                letter-spacing: rem(1px);
            }

            .svg-icon{
                &--logo-symbol {
                    width: rem(35px);
                }
            }

            .container-fluid {
                > .row {
                    min-height: rem(87px);
                }
            }
        }

        &__content {
            > .container-fluid {
                > .row {
                    min-height: calc(100vh - #{rem(88px)});
                    padding-bottom: rem(156px);
    
                    > div {
                        padding-top: rem(30px);
                        padding-bottom: rem(30px);
                    }
                }
            }
        }

        &__footer {
            position: fixed;
            left: 0;
            bottom: rem(50px);
            z-index: 1000;
            width: 100%;
        }
    }
}
