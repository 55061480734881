.navigation {
    // position: relative;
    padding-right: 22%;
    text-align: right;

    &__toggle {
        position: relative;
        vertical-align: top;
        width: rem(32px);
        height: rem(32px);
        padding: 0;
        background: none;
        border: none;

        &:hover {
            color: theme-color("primary");
        }

        &:focus {
            outline: none;
        }

        .svg-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            
            &--list {
                width: rem(23px);
            }
            
            &--close {
                width: rem(16px);
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
        width: 100vw;
        max-width: rem(320px);
        padding: $grid-gutter-width;
        text-align: left;
        background: #fff;
        border-bottom-left-radius: rem(10px);
        opacity: 0;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        transform: translateY(-100%);

        &._shown {
            transform: translateY(0);
            opacity: 1;
        }

        .navigation__toggle {
            position: absolute;
            top: rem(15px);
            right: $grid-gutter-width/2;
        }

        .navigation-title {
            display: block;
            margin-bottom: rem(30px);
            font-size: rem(15px);
            font-weight: 600;
            letter-spacing: rem(0.5px);
        }

        .navigation-nav {
            max-height: rem(300px);
            padding: $grid-gutter-width;
            margin: 0 -$grid-gutter-width;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
}
