@import "variables";
@import '~bootstrap/scss/bootstrap.scss';

$theme-colors: map-merge(
    $theme-colors,
    (
        "tertiary":    $tertiary
    )
);

@import "mixins/all";
@import "base/all";
@import "blocks/all";
