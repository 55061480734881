.form-input {
    position: relative;
    margin-bottom: rem(20px);

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: rem(63px);
        height: rem(63px);
        background: #fff escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='11' height='9' fill='#{$secondary}' viewBox='0 0 11 9'><path d='M5.57223841,-0.185725394 C5.80682689,-0.439128528 6.18632156,-0.478195906 6.46513115,-0.293458685 L6.55400172,-0.223577563 L10.2375087,3.18643669 C10.4401487,3.37403107 10.4523456,3.69037852 10.2647512,3.8930185 C10.0980007,4.07314292 9.82951928,4.10279378 9.62959486,3.97540557 L9.55816941,3.92026097 L6.117,0.735 L0.392923508,8.00921047 C0.241125972,8.20210439 -0.0241205426,8.25315057 -0.233600181,8.14217396 L-0.309210473,8.09292351 C-0.502104394,7.94112597 -0.553150565,7.67587946 -0.442173958,7.46639982 L-0.392923508,7.39078953 L5.5360968,-0.143403725 C5.55360232,-0.165047297 5.55360232,-0.165047297 5.57223841,-0.185725394 Z' transform='translate(4.948910, 3.895760) rotate(-180.000000) translate(-4.948910, -3.895760) '></path></svg>")) center center no-repeat;
        background-size: 20px 14px;
        border: theme-color("secondary") 1px solid;
        border-radius: 100%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &__label {
        position: absolute;
        left: rem(30px);
        top: 50%;
        z-index: 20;
        padding: 0 rem(10px);
        font-size: rem(13px);
        font-weight: 400;;
        letter-spacing: rem(0.5px);
        background: #fff;
        transform: translateY(-50%);
        transition: top 0.3s ease-in-out;
        cursor: text;

        span {
            font-size: rem(11px);
            letter-spacing: rem(0.3px);
            color: #BDBDBD;
        }
    }

    &__control {
        height: rem(63px);
        padding: rem(20px) rem(40px);
        font-size: rem(16px);
        letter-spacing: rem(0.5px);
        border-radius: rem(100px);

        &:focus {
            border-color: theme-color("secondary");
            box-shadow: none;
        }
    }

    &--filled,
    &:focus-within {
        .form-input__label {
            top: 0;
        }
    }

    &--filled {
        &::after {
            opacity: 1;
        }
    }

    &--textarea {
        .form-input {
            &__label {
                top: 0;
            }
            
            &__control {
                min-height: rem(110px);
                font-size: rem(15px);
                border-color: #DDD;
                border-radius: rem(4px);

                &::-webkit-input-placeholder {
                    color: #ddd;
                    opacity: 1;
                }

                &::-moz-placeholder {
                    color: #ddd;
                    opacity: 1;
                }

                &:-ms-input-placeholder {
                    color: #ddd;
                    opacity: 1;
                }

                &:-moz-placeholder {
                    color: #ddd;
                    opacity: 1;
                }
            }
        }

        &.form-input {
            &--filled {
                &::after {
                    display: none;
                }
            }
        }
    }

    &--select {
        .form-input {
            &__label {
                top: 0;
            }

            &__control {
                background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17' height='10' viewBox='0 0 17 10'><g id='mockup' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='008V2-BTB-&gt;-TABLET-&gt;-Materiale-Laterale-Francese' transform='translate(-729.000000, -440.000000)' fill='#{$secondary}' fill-rule='nonzero'><g id='Group-4' transform='translate(116.000000, 278.340909)'><g id='Group-7' transform='translate(373.000000, 131.500000)'><polygon id='Combined-Shape' transform='translate(248.131728, 31.500000) scale(1, -1) rotate(-135.000000) translate(-248.131728, -31.500000) ' points='242.381728 37.25 242.381728 36.25 252.881 36.249 252.881728 25.75 253.881728 25.75 253.881728 37.25'></polygon></g></g></g></g></svg>")) 95% center no-repeat;
                background-size: rem(17px) rem(10px);
            }

            &::after {
                display: none;
            }
        }

        &.form-input {
            &--filled {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.no-form-margin-mobile .form-input {
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }
}

.no-form-margin-desktop .form-input {
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}
